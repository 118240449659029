/* blog --------------------- */

.blog_container {
  display: flex;
  width: 100%;
  padding: 50px 0;
}

.blog_container_left {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog_container_right {
  padding:0;
  width: 100%;
}

.blog_content_heading {
  color: #101828;
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 20px;
}

.blog_content {
  color: #121330;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
.blog_contentsub {
  text-align: center;
}

/* blog card */
.blog_card {
  border-radius: 10px;
  background: #fff;
 border-radius: 10px;
 border:1px solid #e1e1e1;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 10px 10px;
  /* height: 402px; */
}

.blog_card_img_container {
  width: 100%;
}

.blog_card_img {
  height: 100%;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.bloglist_cardbottom {
  padding: 25px 20px;
  display: flex;
  gap: 5px;min-height:240px;
  flex-direction: column;
}

.blog_card_content > p.bloglist-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upcomingblog {padding-top: 5px;font-weight: 400;font-size: 16px;line-height: 26px;color:#222222;}
.blog_container_right .slick-prev,.blog_container_right .slick-next{background:#DAECFF;width:40px;height:40px;border-radius: 100%;}
.blog_container_right .slick-prev:before{transform: rotate(180deg);}
.blog_container_right .slick-next:before,.blog_container_right .slick-prev:before {content:"";background: url(../../Helper/images/new/slider_arrow.svg) no-repeat;
width: 18px;height: 16px;content: "";left: 50%;top: 50%;position: absolute;margin-top: -8px;margin-left: -9px;
background-size: 100%;}


.blog_container_right .slick-prev {
  left:0 !important;z-index: 2;
}
.blog_container_right .slick-next {
  right:0 !important;
}

.blog_container_right .slick-dots li.slick-active button:before {
  color: #002E5D !important;
}
.blog_container_right .slick-dots li button:before {
  font-size: 12px !important;
}
.blog_container_right .slick-track {
  margin-left: 0;
  margin-right: 0;
}
.blog_container_right .bloglist-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}
.blog_container_right .bloglist-name {
  font-weight: 600;
  font-size: 19px;
  line-height: 30px;
  color: #000;
}
.blog_container_right .bloglist-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #222;
}

/*  -------- */

@media only screen and (max-width: 1261px) {
  .blog_card_content > p {
    -webkit-line-clamp: 3;
  }
}
@media only screen and (max-width: 1087px) {
  .blog_card_content > p {
    -webkit-line-clamp: 2;
  }
}
@media only screen and (max-width: 1024px) {
.blog_container_right .bloglist-name {overflow: hidden;text-overflow: ellipsis;display: -webkit-box;
  -webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .blog_container_right .bloglist-desc{text-align: justify;}
}
@media only screen and (max-width: 767px) {
  .blog_container {
    flex-direction: column;
    padding: 30px 0;
  }

  .blog_container_left,
  .blog_container_right {
    width: 100%;
  }
  .blog_container_left > .blog_content_heading {
    text-align: center;
  }
  .blog_container_left > .blog_content {
    padding-bottom: 35px;
    text-align: center;
  }
}
@media only screen and (max-width: 568px) {
  .blog_card_content > p {
    -webkit-line-clamp: 2;
  }

  .blog_card_content {
    padding: 16px;
  }

  .blog_container_right {
    padding: 0;
  }
  .feature_subheading {
    font-size: 24px;
    line-height: 34px;
  }
}
