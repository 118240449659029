.applicationlistcard{border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 8px;max-width: 398px;height:100%;}
.applicationtopcrd{border-top-left-radius:8px;border-top-right-radius:8px;overflow: hidden;height:210px;display: flex;justify-content: center;}
.applicationtopcrd img{max-width: 100%;max-height: 100%;}
.applicationlistcardouter{display: flex;gap:20px;margin-top: 40px;position: relative;}
.applicationbottomcrd h3{font-weight: 500;font-size: 18px;line-height: 21.6px;color: #222222;
  overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;}
.applicationbottomcrd p{font-size: 16px;line-height: 24px;color: #222222;}
.applicationbottomcrd{padding:20px;display: flex;gap:15px;flex-direction: column;}
.applicationtopcrd img{max-width: 100%;transition: transform 0.4s ease-in-out;}
.applicationtopcrd:hover img {transform: scale(1.1);}
/* Slick Slider Container */
.applicationlistcardouter .slick-slider {width: 100%;position: relative;}
/* Slide Content */
  .applicationlistcardouter .slick-slide {display: flex !important;justify-content: center;
    align-items: center;
    padding: 20px 10px;
    transition: transform 0.3s ease;
  } 
  .applicationlistcardouter .slick-slide > div {display: flex;flex-direction: column;height: 100%;}

  /* Navigation Arrows */
  .applicationlistcardouter .slick-prev,
  .applicationlistcardouter .slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;
    background-color:#DAECFF;
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .applicationlistcardouter .slick-prev:before,
  .applicationlistcardouter .slick-next:before {
    background: url(../Helper/images/new/slider_arrow.svg) no-repeat;width:18px;height:16px;content: "";
  }
  .applicationlistcardouter .slick-prev:before{ transform: rotate(180deg);}
  
  /* Positioning Arrows */
  .applicationlistcardouter .slick-prev {
    left:0;
  }
  
  .applicationlistcardouter .slick-next {
    right:0;
  }
  
  /* Dots Navigation */
  .applicationlistcardouter .slick-dots {
    bottom: -25px;
  }
  
  .applicationlistcardouter .slick-dots li button:before {
    font-size: 12px;
    color: #bbb;
  }
  
  @media (max-width:768px){.applicationlistcardouter .slick-next,.applicationlistcardouter .slick-prev{width:30px;height:30px}.applicationlistcardouter .slick-prev{left:0}.applicationlistcardouter .slick-next{right:0}}
  

